<template>
  <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
    <!--<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
        <b-img
          fluid
          :src="getImgLogo"
          alt="Login V2"
        />
      </div>-->
    <!--src="https://i.ibb.co/608bGhJ/logo-atacc.jpg"-->
    <b-img
      :src="getImgLogo"
      fluid
      :style="customImgStyle"
      alt="Fluid-grow image"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index'
import {
  BImg,
} from 'bootstrap-vue'

export default {
  name: 'LogoComponent',
  components: {
    BImg,
  },
  data() {
    return {
      logoImg: require('@/assets/images/logo/isologotipoatacc.png'),
    }
  },
  computed: {
    getImgLogo() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.logoImg = require('@/assets/images/logo/isologotipoatacc.png')
        return this.logoImg
      }
      return this.logoImg
    },
    customImgStyle() {
      const style = {}
      if (!this.appName) {
        style.maxWidth = '90%'
      }
      return style
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
