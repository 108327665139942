<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div>

      <b-overlay
        id="olyLogin"
        :show="isLoadingLogin"
        :rounded="overlayConfig.rounded"
        :variant="overlayConfig.variant"
        :opacity="overlayConfig.opacity"
        :blur="overlayConfig.blur"
      >
        <template #overlay>
          <div
            v-if="isLoadingLogin"
            class="mt-2"
          >
            <div style="text-align: center">
              <span class="spinner-border " />
            </div>
            <hr>
            <div style="text-align: center">
              <h4>{{ overlayConfig.message }}</h4>
            </div>
          </div>
        </template>

        <b-card class="mb-0">
          <!--
          <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <b-img
              fluid
              :src="imgAtacc"
              alt="Login V2"
            />
          </div>
          -->
          <b-card-title
            title-tag="h3"
            class="font-weight-bold mb-1 text-center"
          >
            <logo-component class="mb-1" />
          </b-card-title>
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- Cuil -->
              <b-form-group label-for="login-cuil">
                <validation-provider
                  name="Cuil"
                  rules="required|min:11"
                >
                  <div slot-scope="{ errors, failedRules }">
                    <cleave
                      id="block"
                      v-model="formUser.cuil"
                      class="form-control"
                      :state="errors.length > 0 ? false:null"
                      :raw="true"
                      :options="options.customDelimiter"
                      autocomplete="off"
                      placeholder="C.U.I.L."
                    />
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >C.U.I.L. obligatorio.
                    </small>
                    <small
                      v-if="failedRules.min"
                      class="text-danger"
                    >C.U.I.L. incompleto.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              <!-- Contraseña -->
              <b-form-group>
                <validation-provider rules="required">
                  <div slot-scope="{ errors, failedRules }">
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="formUser.password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Contraseña"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="failedRules.required"
                      class="text-danger"
                    >Ingrese su contraseña.
                    </small>
                  </div>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Ingresar
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'register'}">
              <span>&nbsp;Registrarse</span>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <b-link :to="{name:'forgotPassword'}">
              <small>Olvidé mi contraseña</small>
            </b-link>
          </b-card-text>
          <b-card-text class="text-center mt-2">
            <small>{{ versionApp }}</small>
          </b-card-text>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Cleave from 'vue-cleave-component'
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BCard, BInputGroup, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import {
  required, confirmed, max, min,
} from '@validations'
import {
  togglePasswordVisibility,
} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {
  authenticationResource,
} from '@/services/authenticationResource'
import {
  doLogin,
} from '@/utils/login/loginUtils'
import { mapGetters, mapActions } from 'vuex'
// import bcrypt from 'bcrypt'
import LogoComponent from './LogoComponent.vue'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCard,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    Cleave,
    LogoComponent,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      versionApp: 'v 1.3.0',
      status: '',
      required,
      confirmed,
      max,
      min,
      isLoadingLogin: false,
      redirectTo: '/',
      formUser: {
        cuil: null,
        password: null,
      },
      overlayConfig: {
        rounded: 'lg',
        variant: 'transparent',
        opacity: '0.3',
        blur: '1rem',
        message: 'Iniciando sesión',
      },
      options: {
        block: {
          blocks: [2, 8, 1],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['-', '-'],
          blocks: [2, 8, 1],
          uppercase: true,
          numericOnly: true,
        },
      },
      sideImg: require('@/assets/images/logo/isologotipoatacc.png'),
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgAtacc() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/logo/isologotipoatacc.png')
        return this.sideImg
      }
      return this.sideImg
    },
    ...mapGetters(
      {
      },
    ),
  },
  methods: {
    ...mapActions([
      'fetchRoles',
      'fetchUsuarioEstado',
      'fetchDelegacion',
      'fetchBeneficioTipo',
      'fetchCategoriaSolicitante',
    ]),
    loginUser() {
      this.isLoadingLogin = true
      authenticationResource().loginUser(this.formUser)
        .then(resultsLoginUser => {
          this.effectiveLogin(resultsLoginUser)
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.isLoadingLogin = false
        })
    },
    effectiveLogin(resultsLoginUser) {
      doLogin(this, resultsLoginUser, this.redirectTo)
      this.fetchRoles()
      this.fetchUsuarioEstado()
      this.fetchDelegacion()
      this.fetchBeneficioTipo()
      this.fetchCategoriaSolicitante()
      this.$emit('loginSuccessful')
    },
    validationForm() {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.loginUser()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
